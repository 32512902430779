import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import moment, { Moment } from 'moment';
import classNames from 'classnames';
import { checkSecurityKeysInObject, generateSecurityKeysInObject } from '../../../api/objectKeys';
import { editSystem, getSystem } from '../../../api/systems';
import AddNodeIcon from '../../../assets/svg/icons/addNote';
import SuccessIcon from '../../../assets/svg/icons/success';
import {
  checkObjectSecurityKeysUrl,
  generateObjectSecurityKeysUrl,
  getObjectBaseUrl,
  getObjectTariffInfoUrl,
} from '../../../constants/api';
import { useAppDispatch, useAppSelector } from '../../../hooks/hooks';
import { useApi } from '../../../hooks/useApi';
import { setHeaderTitle } from '../../../store/slices/header';
import { EContactsStatus, ESystemDeviceMode, IObjectTariffInfo, ISystemBase } from '../../../typings/systems/base';
import TabNavButtons from '../../tabs/tabNavButtons';
import { tabNavButtonsDefault } from '../../tabs/tabNavButtons/utils';
import Button from '../../ui/button';
import { ButtonSize, ButtonType } from '../../ui/button/types';
import Checkbox from '../../ui/checkbox';
import ErrorPlaceholder from '../../ui/errorPlaceholder';
import Input from '../../ui/input';
import { IInputTAdressValue, InputStatus, InputType, defaultAddressValue } from '../../ui/input/types';
import Modal from '../../ui/modal';
import Select from '../../ui/select';
import { ISystemTabComponent } from '../types';
import { ESystemTabErrorCodes } from '../../../api/systems/types';
import Loader from '../../ui/loader';
import { ELoaderColor } from '../../ui/loader/types';
import { paths } from '../../../constants/paths';
import Tooltip from '../../ui/tooltip';
import { ETariffVersions } from '../../../typings/management/tariff';
import { getProfilePermission } from '../../../store/selectors/profile';
import { ESidebarItemIds } from '../../../typings/sidebar';
import KeyUtility from '../../ui/keysUtility';
import { EPagesWithKeys } from '../../ui/keysUtility/types';
import InfoIcon from '../../../assets/svg/icons/info';
import InputDaData from '../../ui/inputDaData';
import { getClickedSidebarTab } from '../../../store/selectors/sidebar';
import { setClickedSidebarTab } from '../../../store/slices/sidebar';
import { IConfirmData } from '../../ui/universalModal/types';
import { defaultConfirm, saveChangesModal } from '../../ui/universalModal/config';
import UniversalModal from '../../ui/universalModal';
import SingleDatePicker from '../../ui/singleDatePicker';
import { dateFormatOnlyTime } from '../../../constants/date';
import Message from '../../message';

const errorText = 'Поле обязательно для заполнения';

const SystemBaseTab: FC<ISystemTabComponent> = (props) => {
  const {
    systemId = '',
    onNextTab = () => {},
    permissions = {},
    tabId = '',
    activeTabKey = '',
    settings,
    isWasChange = false,
    setChange = () => {},
    chancelChangeTab = () => {},
    clickedTab = '',
    repeatChangeTab = () => {},
    isFiveThousandth,
    updateAvailableTabs = () => {},
  } = props;

  const dispatch = useAppDispatch();
  const { data, sendRequest, loading: systemLoading } = useApi<ISystemBase>(getSystem);
  const {
    data: objectTariffInfo,
    sendRequest: getObjectTariffInfo,
    loading: objectTariffInfoLoading,
  } = useApi<IObjectTariffInfo>(getSystem);
  const { sendRequest: sendEditSystem, loading: editLoading } = useApi(editSystem);

  const [system, setSystem] = useState<ISystemBase | null>(null);
  const initialSystem = useRef<ISystemBase | null>(null);
  const [nameErrorMessage, setNameErrorMessage] = useState<string>('');
  const [shortNameErrorMessage, setShortNameErrorMessage] = useState<string>('');

  const [systemAddressData, setSystemAddressData] = useState<IInputTAdressValue>({ ...defaultAddressValue });

  const { sendRequest: sendRequestGenerateSecurityKeys, loading: keyLoading } = useApi(generateSecurityKeysInObject);
  const {
    data: isValidSecurityKey,
    sendRequest: sendRequestSecurityKey,
    loading: keyValidLoading,
  } = useApi<boolean>(checkSecurityKeysInObject);
  const [keyGenModalIsOpen, setKeyGenModalIsOpen] = useState<boolean>(false);

  const canViewTariffs = useAppSelector(getProfilePermission(ESidebarItemIds.tariffCost))?.view;
  const canViewCompanyInfo = useAppSelector(getProfilePermission(ESidebarItemIds.organizationInfo))?.view;

  const navigate = useNavigate();

  const clickedSidebarTab = useAppSelector(getClickedSidebarTab);

  const [confirmData, setConfirmData] = useState<IConfirmData>(defaultConfirm);

  const [emptyUdFields, setEmptyUdFields] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const [emptyDateFields, setEmptyDateFields] = useState(false);
  const [dateFieldsError, setDateFieldsError] = useState(false);

  const [scCallCodeError, setScCallCodeError] = useState('');

  const closeConfirm = useCallback(() => {
    setConfirmData(defaultConfirm);
    if (clickedTab) {
      chancelChangeTab();
    }
  }, [setConfirmData, clickedTab, chancelChangeTab]);

  const checkSecurityKey = useCallback(async () => {
    if (systemId) {
      await sendRequestSecurityKey(checkObjectSecurityKeysUrl(systemId));
    }
  }, [sendRequestSecurityKey, systemId]);

  useEffect(() => {
    if (tabId === activeTabKey && systemId) {
      checkSecurityKey();
      sendRequest(getObjectBaseUrl(systemId));
      if (canViewTariffs) {
        getObjectTariffInfo(getObjectTariffInfoUrl(systemId));
      }
    }
  }, [activeTabKey, systemId]);

  useEffect(() => {
    if (data) {
      setSystem(data);
      setSystemAddressData({
        ...defaultAddressValue,
        fiasId: data.fiasId,
        address: data.address,
        searchValue: data.address,
        isFinalAddress: true,
      });
      initialSystem.current = data;
      dispatch(setHeaderTitle(data.shortName || ''));
    }
  }, [data, dispatch]);

  const validate = useCallback(() => {
    let isValid = true;

    if (
      system &&
      system.connectorSetting &&
      (!system.connectorSetting.host ||
        !system.connectorSetting.login ||
        !system.connectorSetting.password ||
        !system.connectorSetting.port)
    ) {
      isValid = false;
      setEmptyUdFields(true);
    }

    if (system && system.withSecurityDesk && (!system.nightModeStart || !system.nightModeEnd)) {
      isValid = false;
      setEmptyDateFields(true);
    }

    if (shortNameErrorMessage || !system?.shortName.trim()) {
      isValid = false;
    }

    if (nameErrorMessage || !system?.objectName.trim()) {
      isValid = false;
    }

    if (systemAddressData.status === InputStatus.error) {
      isValid = false;
    }

    if (isFiveThousandth && system && !system.globalNetPassword && system.globalNetPassword !== 0) {
      isValid = false;
      setPasswordError(true);
    }

    return isValid;
  }, [isFiveThousandth, nameErrorMessage, shortNameErrorMessage, system, systemAddressData.status]);

  const handleOnSaveSystemBase = useCallback(
    async (editSystemBase = system) => {
      if (systemId && validate()) {
        setChange(false);
        const newEditSystemBase: any = { ...editSystemBase };

        if (!settings?.daDataSearch) {
          delete newEditSystemBase.fiasId;
        }

        const resError = await sendEditSystem(getObjectBaseUrl(systemId), {
          ...newEditSystemBase,
          keyNumbers: editSystemBase?.isDefaultKeyNumber ? editSystemBase.keyNumbers : null,
        });

        const resData = resError?.response?.data;

        if (!resData) {
          await sendRequest(getObjectBaseUrl(systemId));
          initialSystem.current = editSystemBase;
          updateAvailableTabs();
          return true;
        }

        if (resData?.errorCodes === ESystemTabErrorCodes.ScCallCode) {
          setScCallCodeError(resData.message);
        } else if (resData?.errorCodes === ESystemTabErrorCodes.ObjectDuplicateName) {
          setNameErrorMessage(resData.message);
        } else if (resData?.errorCodes === ESystemTabErrorCodes.ObjectDuplicateShortName) {
          setShortNameErrorMessage(resData.message);
        } else if (
          resData?.errorCodes === ESystemTabErrorCodes.InvalidSecurityDeskTime ||
          resData?.errorCodes === ESystemTabErrorCodes.NightAndDayTimeCannotBeEqual
        ) {
          setDateFieldsError(true);
        }
        return false;
      }
      return false;
    },
    [sendEditSystem, sendRequest, setChange, settings?.daDataSearch, system, systemId, updateAvailableTabs, validate]
  );

  const handleOnCancel = useCallback(() => {
    if (initialSystem) {
      if (initialSystem.current) {
        setSystem(initialSystem.current);
        setSystemAddressData({
          ...defaultAddressValue,
          fiasId: initialSystem.current.fiasId,
          address: initialSystem.current.address,
          searchValue: initialSystem.current.address,
          isFinalAddress: true,
        });
        sendRequest(getObjectBaseUrl(systemId));
      }
      setChange(false);
    }
  }, [sendRequest, setChange, systemId]);

  const checkChanges = useCallback(
    (callBack: () => void) => {
      if (isWasChange) {
        setConfirmData(
          saveChangesModal(
            async () => {
              closeConfirm();
              if (await handleOnSaveSystemBase()) {
                callBack();
              }
            },
            () => {
              closeConfirm();
              handleOnCancel();
              callBack();
            }
          )
        );
      } else {
        callBack();
      }
    },
    [closeConfirm, handleOnCancel, handleOnSaveSystemBase, isWasChange]
  );

  const onClickedSidebarTabChange = useCallback(() => {
    if (clickedSidebarTab) {
      checkChanges(() => {
        setChange(false);
        navigate(clickedSidebarTab);
        dispatch(setClickedSidebarTab(null));
      });
    }
  }, [checkChanges, clickedSidebarTab, dispatch, navigate, setChange]);

  useEffect(() => {
    onClickedSidebarTabChange();
  }, [clickedSidebarTab]);

  useEffect(() => {
    if (clickedTab && activeTabKey === tabId) {
      checkChanges(() => {
        repeatChangeTab();
        setChange(false);
      });
    }
  }, [clickedTab]);

  const getUpdatedSystem = useCallback((currentSystem: ISystemBase, key: string, value: any) => {
    const newSystem: ISystemBase = {
      ...currentSystem,
      [key]: value,
    };

    return newSystem;
  }, []);

  const handleOnChangeStringField = useCallback(
    async (value: string | null, fieldName: any) => {
      if (system) {
        setSystem(getUpdatedSystem(system, fieldName, value));
        if (!isWasChange) {
          setChange(true);
        }
      }
    },
    [system, getUpdatedSystem, isWasChange, setChange]
  );

  const onChangeDaData = useCallback(
    (value: IInputTAdressValue) => {
      handleOnChangeStringField(value.address, 'address');
      setSystemAddressData(value);
      if (!isWasChange) {
        setChange(true);
      }
    },
    [handleOnChangeStringField, isWasChange, setChange]
  );

  const handleOnBlurDaData = useCallback(async () => {
    const initialFieldValue = initialSystem.current?.address;

    if (
      systemAddressData.status === InputStatus.normal &&
      systemAddressData.isFinalAddress &&
      system &&
      systemAddressData.address !== initialFieldValue
    ) {
      setSystem(getUpdatedSystem(system, 'address', systemAddressData.address));
    }
  }, [getUpdatedSystem, system, systemAddressData.address, systemAddressData.isFinalAddress, systemAddressData.status]);

  const handleOnBlurInput = useCallback(
    async (fieldName: keyof ISystemBase) => {
      if (initialSystem.current && system) {
        const fieldValue = system[fieldName];

        if (!system.fiasId) {
          setSystemAddressData({
            ...systemAddressData,
            errorText: 'Заполните адрес повторно',
            status: InputStatus.error,
          });
        } else if (systemAddressData.status === InputStatus.normal) {
          setSystem(getUpdatedSystem(system, fieldName, fieldValue));
        }
      }
    },
    [getUpdatedSystem, system, systemAddressData]
  );

  const handleOnChangeNumberField = useCallback(
    async (value: string, fieldName: string) => {
      if (system) {
        setSystem(getUpdatedSystem(system, fieldName, value === '' ? '' : Number(value)));
        if (!isWasChange) {
          setChange(true);
        }
      }
    },
    [system, getUpdatedSystem, isWasChange, setChange]
  );

  const handleOnChangeCheckbox = useCallback(
    async (value: boolean, key?: string) => {
      if (key && system) {
        setSystem(getUpdatedSystem(system, key, value));
        if (!isWasChange) {
          setChange(true);
        }
      }
    },
    [system, getUpdatedSystem, isWasChange, setChange]
  );

  const handleOnCloseKeyGenModal = useCallback(async () => {
    await checkSecurityKey();
    setKeyGenModalIsOpen(false);
  }, [checkSecurityKey]);

  const goToPage = useCallback(
    (page: string) => () => {
      navigate({
        pathname: page,
        search: `?${createSearchParams({ systemId, companyId: system?.companyId || '' })}`,
      });
    },
    [navigate, system?.companyId, systemId]
  );

  const handleOnGenerateSecurityKey = useCallback(async () => {
    if (systemId) {
      await sendRequestGenerateSecurityKeys(generateObjectSecurityKeysUrl(systemId));
      setKeyGenModalIsOpen(true);
    }
  }, [sendRequestGenerateSecurityKeys, systemId]);

  const onChangeConnectorSetting = useCallback(
    (field: string) => (value: any) => {
      if (field && system) {
        setSystem(getUpdatedSystem(system, 'connectorSetting', { ...system.connectorSetting, [field]: value }));
        if (!isWasChange) {
          setChange(true);
        }
      }
    },
    [getUpdatedSystem, isWasChange, setChange, system]
  );

  const handleOnChangeUdCheckbox = useCallback(
    (val: boolean) => {
      if (system) {
        setSystem(
          getUpdatedSystem(
            system,
            'connectorSetting',
            val ? { host: '', port: '', login: '', password: '', adapterName: '' } : null
          )
        );
        if (emptyUdFields) setEmptyUdFields(false);
        if (!isWasChange) setChange(true);
      }
    },
    [emptyUdFields, getUpdatedSystem, isWasChange, setChange, system]
  );

  const onChangeTimePicker = useCallback(
    (field: string) => (date: Moment | null) => {
      handleOnChangeStringField(date?.format(dateFormatOnlyTime) || null, field);
      setDateFieldsError(false);
    },
    [handleOnChangeStringField]
  );

  const tryToChangePPOCheckbox = useCallback(
    (val: boolean) => {
      if (val) handleOnChangeCheckbox(true, 'withSecurityDesk');
      else
        setConfirmData({
          isOpen: true,
          description: 'При отключении ППО настройки перенаправления для квартир будут сброшены. Сохранить изменения?',
          buttons: [
            {
              label: 'Да',
              type: ButtonType.primary,
              onClick: () => {
                closeConfirm();
                if (system) {
                  let newSystem = { ...system };
                  newSystem = getUpdatedSystem(newSystem, 'withSecurityDesk', false);
                  newSystem = getUpdatedSystem(newSystem, 'nightModeEnd', null);
                  newSystem = getUpdatedSystem(newSystem, 'nightModeStart', null);
                  setSystem(newSystem);
                  if (!isWasChange) {
                    setChange(true);
                  }
                }
                handleOnChangeCheckbox(false);
              },
            },
            {
              label: 'Нет',
              type: ButtonType.secondary,
              onClick: closeConfirm,
            },
          ],
        });
    },
    [closeConfirm, getUpdatedSystem, handleOnChangeCheckbox, isWasChange, setChange, system]
  );

  const getTooltipStatus = useCallback(
    (isSet: boolean, setLabel = 'Наценка задана', notSetLabel = 'Наценка не задана') => (
      <Tooltip title={isSet ? setLabel : notSetLabel} placement="bottom">
        <div className={`system-base-tab__status-icon system-base-tab__status-icon${isSet ? '_green' : '_red'}`} />
      </Tooltip>
    ),
    []
  );

  const disabledBtn = useMemo(
    () =>
      !isWasChange ||
      !system?.objectName.trim() ||
      !!nameErrorMessage ||
      !system?.shortName.trim() ||
      !!shortNameErrorMessage ||
      systemAddressData.status === InputStatus.error,
    [
      isWasChange,
      nameErrorMessage,
      shortNameErrorMessage,
      system?.objectName,
      system?.shortName,
      systemAddressData.status,
    ]
  );

  const isMifareOrEМMarin = useMemo(
    () => system?.secureKeyMode === ESystemDeviceMode.miFare || system?.secureKeyMode === ESystemDeviceMode.emMarin,
    [system?.secureKeyMode]
  );

  if (!system || systemLoading) {
    return (
      <div className="system-item__loader-wrapper">
        <Loader color={ELoaderColor.blue} />
      </div>
    );
  }

  return (
    <div className="system-base-tab">
      <UniversalModal data={confirmData} onClose={closeConfirm} />

      {(systemLoading || editLoading || keyLoading || keyValidLoading || objectTariffInfoLoading) && (
        <div className="system-item__loader-wrapper">
          <Loader color={ELoaderColor.blue} />
        </div>
      )}
      <Modal
        centered
        title="Запущен процесс генерации ключей безопасности. Следуйте указаниям в приложении для работы со считывателем"
        isOpen={keyGenModalIsOpen}
        width={416}
        onCancel={handleOnCloseKeyGenModal}
        wrapClassName="system-base-tab__modal"
        footer={
          <Button size={ButtonSize.small} onClick={handleOnCloseKeyGenModal}>
            Ок
          </Button>
        }
      />
      <div className="system-base-tab__content-wrapper">
        <Input
          title="Наименование"
          inputClassName="system-base-tab__input"
          value={system.objectName}
          onChange={(value) => {
            handleOnChangeStringField(value, 'objectName');
            setNameErrorMessage('');
          }}
          status={system.objectName && !nameErrorMessage ? InputStatus.normal : InputStatus.error}
          errorText={system.objectName ? nameErrorMessage : errorText}
          maxLength={400}
          isRequired
          onBlur={() => handleOnBlurInput('objectName')}
          disabled={!permissions.edit}
        />
        <InputDaData
          daDataSearch={settings?.daDataSearch}
          saveAfterBlur={handleOnBlurDaData}
          disabled={!permissions.edit}
          inputClassName="system-base-tab__input"
          onChange={onChangeDaData}
          value={systemAddressData}
        />
      </div>
      <div
        className={classNames('system-base-tab__content-wrapper system-base-tab__content-wrapper_mt', {
          'system-base-tab__content-wrapper_3': !isFiveThousandth,
          'system-base-tab__content-wrapper_4': isFiveThousandth,
        })}
      >
        <Input
          title="Краткое наименование"
          inputClassName="system-base-tab__input"
          value={system.shortName}
          onChange={async (value) => {
            handleOnChangeStringField(value, 'shortName');
            setShortNameErrorMessage('');
          }}
          status={system.shortName && !shortNameErrorMessage ? InputStatus.normal : InputStatus.error}
          errorText={system.shortName ? shortNameErrorMessage : errorText}
          maxLength={55}
          isRequired
          onBlur={() => handleOnBlurInput('shortName')}
          disabled={!permissions.edit}
        />
        <Select
          title="Серия оборудования"
          value={data?.deviceSeriesName}
          disabled
          isRequired
          isDisabledStyle
          options={[
            {
              value: data?.deviceSeriesName,
              title: data?.deviceSeriesName || '',
            },
          ]}
        />
        <Select
          title="Режим работы с ключами"
          value={system.secureKeyMode || ESystemDeviceMode.none}
          disabled
          isDisabledStyle
          options={[
            {
              value: system.secureKeyMode || ESystemDeviceMode.none,
              title: system.secureKeyMode || ESystemDeviceMode.none,
            },
          ]}
        />
        {isFiveThousandth && (
          <Input
            title="Пароль сети объекта"
            placeholder="Пароль"
            maxLength={8}
            isRequired
            isDisabledStyle
            inputClassName="system-base-tab__input"
            inputType={InputType.number}
            value={system.globalNetPassword?.toString()}
            onChange={(value) => {
              handleOnChangeNumberField(value, 'globalNetPassword');
              setPasswordError(false);
            }}
            disabled={!permissions.edit || system.globalNetPasswordDisabled}
            status={passwordError ? InputStatus.error : undefined}
            errorText={passwordError ? errorText : ''}
          />
        )}
      </div>
      <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-top">
        <span className="system-base-tab__subtitle">Настройки квартир по умолчанию</span>
        {!isMifareOrEМMarin && (
          <span className="system-base-tab__subtitle">
            Ключи безопасности <KeyUtility pageStyle={EPagesWithKeys.mainPage} />
          </span>
        )}
      </div>
      <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-small-top">
        <div className="system-base-tab__settings-container">
          <div className="system-base-tab__setting-block system-base-tab__setting-block_flex">
            <span className="system-base-tab__setting-title">Флаги квартир для БВ</span>
            <Checkbox
              label="Прямой вызов"
              checked={system.isDirectCall}
              valueKey="isDirectCall"
              onChange={handleOnChangeCheckbox}
              disabled={!permissions.edit}
            />
            <Checkbox
              label="Отзвонка"
              checked={system.isNotificationCallBack}
              valueKey="isNotificationCallBack"
              onChange={handleOnChangeCheckbox}
              disabled={!permissions.edit}
            />
            {isFiveThousandth && (
              <Checkbox
                label="Видео"
                checked={system.isVideo}
                valueKey="isVideo"
                onChange={handleOnChangeCheckbox}
                disabled={!permissions.edit}
              />
            )}
            {isFiveThousandth && (
              <Checkbox
                label="Блокировка"
                checked={system.isBlocked}
                valueKey="isBlocked"
                onChange={handleOnChangeCheckbox}
                disabled={!permissions.edit}
              />
            )}
            {isFiveThousandth && (
              <Checkbox
                label="Обратный вызов"
                checked={system.isCallback}
                valueKey="isCallback"
                onChange={handleOnChangeCheckbox}
                disabled={!permissions.edit}
              />
            )}
          </div>
          <div className="system-base-tab__setting-block">
            <span className="system-base-tab__setting-title">Ключей в квартире</span>
            <Checkbox
              label="Задавать по умолчанию"
              checked={system.isDefaultKeyNumber}
              valueKey="isDefaultKeyNumber"
              onChange={handleOnChangeCheckbox}
              disabled={!permissions.edit}
            />
            <Input
              title="Количество ключей"
              containerClassName="system-base-tab__setting-input"
              onChange={(value) => handleOnChangeNumberField(value, 'keyNumbers')}
              value={system.isDefaultKeyNumber ? Number(system.keyNumbers || '').toString() : ''}
              maxLength={5}
              disabled={!system.isDefaultKeyNumber || !permissions.edit}
              inputType={InputType.numbers}
            />
          </div>
        </div>
        {!isMifareOrEМMarin && (
          <div className="system-base-tab__object-key-container">
            {isValidSecurityKey ? (
              <ErrorPlaceholder
                icon={<SuccessIcon />}
                text={<div className="system-base-tab__bold">Ключи безопасности сгенерированы</div>}
                containerClassName="system-base-tab__key-block"
                textContainerClassName="system-base-tab__key-item"
                iconContainerClassName="system-base-tab__key-block-icon"
              />
            ) : (
              <ErrorPlaceholder
                icon={<AddNodeIcon />}
                text="Для настройки квартирных ключей сгенерируйте ключи безопасности"
                containerClassName="system-base-tab__key-block"
                textContainerClassName="system-base-tab__key-item"
              >
                {permissions.edit && (
                  <div className="system-base-tab__key-item">
                    <Button type={ButtonType.outline} size={ButtonSize.small} onClick={handleOnGenerateSecurityKey}>
                      Сгенерировать
                    </Button>
                  </div>
                )}
              </ErrorPlaceholder>
            )}
          </div>
        )}
      </div>

      {(canViewTariffs || canViewCompanyInfo) && !isFiveThousandth && (
        <>
          <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-top">
            {canViewTariffs && <span className="system-base-tab__subtitle">Стоимоcть тарифов</span>}
            {canViewCompanyInfo && <span className="system-base-tab__subtitle">Контакты для абонентов</span>}
          </div>
          <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-small-top">
            {canViewTariffs && (
              <div className="system-base-tab__tariff-container">
                {!objectTariffInfo?.isSetAdditionalCompanyPrice &&
                !objectTariffInfo?.isSetMaintenance &&
                !objectTariffInfo?.isSetObjectTariffDiscount &&
                objectTariffInfo?.isSetTariffAdditionalCompanyPrice.length === 0 ? (
                  <ErrorPlaceholder
                    icon={<AddNodeIcon />}
                    text={
                      <div className="system-base-tab__bold">
                        Тарифы не настроены, оплаты {objectTariffInfo?.isObjectPaymentEnabled ? '' : 'не'} включены
                      </div>
                    }
                    containerClassName="system-base-tab__key-block"
                  >
                    Для редактирования перейдите в раздел
                    <div
                      className="system-base-tab__link"
                      role="presentation"
                      onClick={goToPage(paths.mpManagementTariffsCost)}
                    >
                      «Стоимость тарифов»
                    </div>
                  </ErrorPlaceholder>
                ) : objectTariffInfo?.isSetAdditionalCompanyPrice &&
                  objectTariffInfo?.isSetMaintenance &&
                  objectTariffInfo?.isSetObjectTariffDiscount &&
                  objectTariffInfo?.isSetTariffAdditionalCompanyPrice.length === 3 ? (
                  <ErrorPlaceholder
                    icon={<SuccessIcon />}
                    text={
                      <div className="system-base-tab__bold">
                        {objectTariffInfo?.isObjectPaymentEnabled
                          ? 'Тарифы настроены, оплаты включены'
                          : 'Тарифы настроены, оплаты не включены'}
                      </div>
                    }
                    containerClassName="system-base-tab__key-block"
                    iconContainerClassName="system-base-tab__key-block-icon"
                  >
                    Для редактирования перейдите в раздел
                    <div
                      className="system-base-tab__link"
                      role="presentation"
                      onClick={goToPage(paths.mpManagementTariffsCost)}
                    >
                      «Стоимость тарифов»
                    </div>
                  </ErrorPlaceholder>
                ) : (
                  <div className="system-base-tab__tariffs-settings">
                    <div className="system-base-tab__tariffs-settings-content">
                      <div className="system-base-tab__tariffs-settings-block">
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            objectTariffInfo?.isSetTariffAdditionalCompanyPrice.indexOf(ETariffVersions.lite) !== -1
                          )}
                          Тариф «Lite»
                        </div>
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            objectTariffInfo?.isSetTariffAdditionalCompanyPrice.indexOf(ETariffVersions.medium) !== -1
                          )}
                          Тариф «Medium»
                        </div>
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            objectTariffInfo?.isSetTariffAdditionalCompanyPrice.indexOf(ETariffVersions.comfort) !== -1
                          )}
                          Тариф «Comfort»
                        </div>
                      </div>
                      <div className="system-base-tab__tariffs-settings-block">
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            !!objectTariffInfo?.isSetMaintenance,
                            'Тех. обслуживание задано',
                            'Тех. обслуживание не задано'
                          )}
                          Тех. обслуживание
                        </div>
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            !!objectTariffInfo?.isSetObjectTariffDiscount,
                            'Периоды и скидки заданы',
                            'Периоды и скидки не заданы'
                          )}
                          Периоды и скидки
                        </div>
                        <div className="system-base-tab__tariff-item">
                          {getTooltipStatus(
                            !!objectTariffInfo?.isObjectPaymentEnabled,
                            'Оплаты включены',
                            'Оплаты не включены'
                          )}
                          Оплаты
                        </div>
                      </div>
                    </div>
                    <div className="system-base-tab__tariffs-settings-footer">
                      Для изменения настроек тарифов перейдите <br /> в раздел «Стоимость тарифов»
                      <Button
                        type={ButtonType.outline}
                        size={ButtonSize.small}
                        onClick={goToPage(paths.mpManagementTariffsCost)}
                      >
                        Перейти
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {canViewCompanyInfo && (
              <div className="system-base-tab__tariff-container">
                {system.isContactsConfigured === EContactsStatus.notConfigured ? (
                  <ErrorPlaceholder
                    icon={<AddNodeIcon />}
                    text="Для настройки контактов объекта перейдите в подраздел «Информация»"
                    containerClassName="system-base-tab__key-block"
                    textContainerClassName="system-base-tab__key-item"
                  >
                    <div className="system-base-tab__key-item">
                      <Button
                        type={ButtonType.outline}
                        size={ButtonSize.small}
                        onClick={goToPage(paths.organizationInfo)}
                      >
                        Перейти
                      </Button>
                    </div>
                  </ErrorPlaceholder>
                ) : system.isContactsConfigured === EContactsStatus.partiallyСonfigured ? (
                  <ErrorPlaceholder
                    icon={<AddNodeIcon />}
                    text="Настроены контакты не для всех строений объекта,
                  перейдите в подраздел «Информация»"
                    containerClassName="system-base-tab__key-block"
                    textContainerClassName="system-base-tab__key-item system-base-tab__contact-item"
                  >
                    <div className="system-base-tab__key-item">
                      <Button
                        type={ButtonType.outline}
                        size={ButtonSize.small}
                        onClick={goToPage(paths.organizationInfo)}
                      >
                        Перейти
                      </Button>
                    </div>
                  </ErrorPlaceholder>
                ) : (
                  <ErrorPlaceholder
                    icon={<SuccessIcon />}
                    text={<div className="system-base-tab__bold">Контакты настроены</div>}
                    containerClassName="system-base-tab__key-block"
                    textContainerClassName="system-base-tab__key-item"
                    iconContainerClassName="system-base-tab__key-block-icon"
                  >
                    Для редактирования перейдите в подраздел <br /> «Информация»
                    <div className="system-base-tab__key-item">
                      <Button
                        type={ButtonType.outline}
                        size={ButtonSize.small}
                        onClick={goToPage(paths.organizationInfo)}
                      >
                        Перейти
                      </Button>
                    </div>
                  </ErrorPlaceholder>
                )}
              </div>
            )}
          </div>
        </>
      )}

      {isFiveThousandth && (
        <>
          <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-top">
            <span className="system-base-tab__subtitle">Настройки UD-CAN-3</span>
            <span className="system-base-tab__subtitle">Настройки ППО</span>
          </div>
          <div className="system-base-tab__content-wrapper system-base-tab__content-wrapper_indent-small-top">
            <div className="system-base-tab__ud-container">
              <div className="system-base-tab__ud-inputs">
                <Checkbox
                  label="Наличие UD-CAN-3"
                  checked={!!system.connectorSetting}
                  onChange={handleOnChangeUdCheckbox}
                  disabled={!permissions.edit}
                />
                <Input
                  isDisabledStyle
                  title="ID шлюза"
                  placeholder="ID шлюза"
                  inputType={InputType.number}
                  value={system.connectorSetting?.remoteId?.toString()}
                  onChange={(val) => onChangeConnectorSetting('remoteId')(Number(val))}
                  isRequired
                  maxLength={10}
                  disabled={!permissions.edit || !system.connectorSetting}
                  status={emptyUdFields && !system.connectorSetting?.remoteId ? InputStatus.error : InputStatus.normal}
                  errorText={emptyUdFields && !system.connectorSetting?.remoteId ? errorText : ''}
                />
              </div>
              <div className="system-base-tab__ud-inputs">
                <Input
                  isDisabledStyle
                  title="Хост"
                  placeholder="Хост"
                  value={system.connectorSetting?.host}
                  onChange={onChangeConnectorSetting('host')}
                  isRequired
                  disabled={!permissions.edit || !system.connectorSetting}
                  status={emptyUdFields && !system.connectorSetting?.host ? InputStatus.error : InputStatus.normal}
                  errorText={emptyUdFields && !system.connectorSetting?.host ? errorText : ''}
                />
                <Input
                  isDisabledStyle
                  title="Логин"
                  placeholder="Логин"
                  inputType={InputType.number}
                  value={system.connectorSetting?.login}
                  onChange={onChangeConnectorSetting('login')}
                  isRequired
                  disabled={!permissions.edit || !system.connectorSetting}
                  status={emptyUdFields && !system.connectorSetting?.login ? InputStatus.error : InputStatus.normal}
                  errorText={emptyUdFields && !system.connectorSetting?.login ? errorText : ''}
                />
              </div>
              <div className="system-base-tab__ud-inputs">
                <Input
                  isDisabledStyle
                  title="Порт"
                  placeholder="Порт"
                  inputType={InputType.number}
                  value={system.connectorSetting?.port}
                  onChange={onChangeConnectorSetting('port')}
                  isRequired
                  disabled={!permissions.edit || !system.connectorSetting}
                  status={emptyUdFields && !system.connectorSetting?.port ? InputStatus.error : InputStatus.normal}
                  errorText={emptyUdFields && !system.connectorSetting?.port ? errorText : ''}
                />
                <Input
                  isDisabledStyle
                  title="Пароль"
                  placeholder="Пароль"
                  value={system.connectorSetting?.password}
                  onChange={onChangeConnectorSetting('password')}
                  isRequired
                  disabled={!permissions.edit || !system.connectorSetting}
                  status={emptyUdFields && !system.connectorSetting?.password ? InputStatus.error : InputStatus.normal}
                  errorText={emptyUdFields && !system.connectorSetting?.password ? errorText : ''}
                />
              </div>
            </div>
            <div className="system-base-tab__ud-container">
              <div className="system-base-tab__ud-inputs">
                <Checkbox
                  label="Наличие ППО"
                  checked={system.withSecurityDesk}
                  onChange={tryToChangePPOCheckbox}
                  disabled={!permissions.edit}
                />
              </div>
              <div className="system-base-tab__ud-inputs">
                <Input
                  containerClassName="system-base-tab__security-code"
                  isDisabledStyle
                  title="Код вызова ППО"
                  inputType={InputType.numbers}
                  placeholder="Код вызова ППО"
                  textInfo="Код вызова поста охраны должен быть уникальным для объекта"
                  value={system.scCallCode}
                  onChange={(val) => {
                    handleOnChangeStringField(val, 'scCallCode');
                    setScCallCodeError('');
                  }}
                  maxLength={5}
                  disabled={!permissions.edit}
                  status={scCallCodeError ? InputStatus.error : InputStatus.normal}
                  errorText={scCallCodeError}
                />
                <div />
              </div>
              <div className="system-base-tab__ud-inputs">
                <SingleDatePicker
                  format={dateFormatOnlyTime}
                  isDisabledStyle
                  title="Время перехода в режим «день»"
                  type="time"
                  isRequired
                  disabled={!system.withSecurityDesk || !permissions.edit}
                  placeholder="-выберите-"
                  containerClassName="system-base-tab__picker"
                  dateValue={system.nightModeStart ? moment(system.nightModeStart, 'HH:mm') : null}
                  onChange={onChangeTimePicker('nightModeStart')}
                  isError={(system.withSecurityDesk && emptyDateFields && !system.nightModeStart) || dateFieldsError}
                  errorText={system.withSecurityDesk && emptyDateFields && !system.nightModeStart ? errorText : ''}
                />
                <SingleDatePicker
                  format={dateFormatOnlyTime}
                  isDisabledStyle
                  type="time"
                  disabled={!system.withSecurityDesk || !permissions.edit}
                  isRequired
                  title="Время перехода в режим «ночь»"
                  placeholder="-выберите-"
                  containerClassName="system-base-tab__picker"
                  dateValue={system.nightModeEnd ? moment(system.nightModeEnd, 'HH:mm') : null}
                  onChange={onChangeTimePicker('nightModeEnd')}
                  isError={(system.withSecurityDesk && emptyDateFields && !system.nightModeEnd) || dateFieldsError}
                  errorText={system.withSecurityDesk && emptyDateFields && !system.nightModeEnd ? errorText : ''}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <TabNavButtons
        buttons={tabNavButtonsDefault(
          {
            callBack: handleOnCancel,
            isHidden: !isWasChange || !permissions.edit,
          },
          {
            callBack: () => handleOnSaveSystemBase(),
            disabled: disabledBtn,
            isHidden: !permissions.edit,
          },
          {
            isHidden: !permissions.edit,
            disabled: disabledBtn,
            callBack: onNextTab,
            classNameOption: 'system-base-tab__button-next',
          }
        )}
      />
    </div>
  );
};

export default SystemBaseTab;
